.groups-dropdown-container {
  width: 256px;
  margin-left: 16px;
}

.groups-dropdown:hover {
  border: 1px solid #dadada;
  background-color: #eee !important;
}

.groups-dropdown:focus {
  background-image: url('../assets/angleDown.svg') !important;
  background-repeat: no-repeat !important;
  background-position: calc(100% - 10px) center !important
}